.container,
.containerRight {
  display: block;
  height: 100%;
  width: 100%;
}

.containerRight {
  display: none;
}

.newsletterContainer,
.newsletterContainerSocial {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newsletterContainerSocial {
  justify-content: flex-start;
  margin-top: var(--spacing72);
}

.newsletterContainerImage {
  height: 0%;
}

.title {
  font-size: var(--fontSizeS);
  font-weight: var(--fontWeightThick);
  margin-top: var(--spacing16);
}

.button {
  margin-top: var(--spacing24);
}

.socialsContainer {
  display: flex;
  margin-top: var(--spacing56);
}

.socialIcon {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: var(--spacing16);
  background-color: black;
}
.socialIcon > a {
  height: 17px;
}

.inputContainer {
  margin-top: var(--spacing32);
  text-align: center;
}

.inputContainer input {
  max-width: 100% !important;
}

.bodyText {
  font-size: var(--fontSizeXS);
}

.image {
  align-self: center;
  width: 100%;
  max-width: 345px;
  display: none;
}

@media (--desktop) {
  .containerRight {
    display: block;
  }
  .title {
    margin-top: 0px;
  }

  .formInfo {
    max-width: 25vw;
  }

  .newsletterContainer {
    width: 50%;
    align-items: flex-start;
    justify-content: center;
    min-height: inherit;
  }

  .inputContainer {
    margin-top: var(--spacing32);
    text-align: start;
  }

  .inputContainer input {
    max-width: initial;
  }

  .newsletterContainerImage {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .image {
    display: block;
    max-width: initial;
    align-self: center;
    margin-top: 2vw;
  }

  .containerRight {
    width: 50%;
  }
}

@media (--desktopXL) {
  .container {
    width: 50%;
  }

  .image {
    width: 70%;
  }
}
