html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--euclidCircularB);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

textarea {
  font-family: var(--euclidCircularB);
}

.carousel__dot--selected {
  /* border: 1px solid var(--colorOrange) !important; */
}

.cky-btn-revisit-wrapper {
  display: none !important;
}

.empty-message {
  width: 100%;
  text-align: center;
  padding-top: 40vh;
  padding-bottom: 40vh;
}