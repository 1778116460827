@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-Light-WebS.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-Light-WebS.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-Medium-WebS.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-Medium-WebS.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
