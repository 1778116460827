.buttonDetail {
  font-size: var(--fontSizeXS);
  border-radius: 50px;
  padding: 12px 24px 12px 24px;
  min-width: 123px;
  cursor: pointer;
  border: none;
  font-weight: var(--fontWeightStandard)
}

.buttonDetail:hover {
  background-color: var(--colorOrange) !important;
}

.buttonDetail[disabled] {
  background-color: var(--colorGrey) !important;
}

.menuButton {
  font-size: var(--fontSizeXXS) !important;
  min-width: 0px;
  padding: 0px 4px 0px 4px;
}

.burgerButton {
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  padding: 0px;
}

.burgerButton > svg {
  fill: white;
  stroke: white;
}

@media (--mobile) {
}

@media (--desktop) {
}

@media (--desktopXL) {
}
