.container {
  display: flex;
  flex-direction: column;
  min-height: 420px;
}

.modalLeft,
.modalRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalLeft {
  max-width: 395px;
  justify-content: space-between;
  padding: 60px 40px;
  max-height: 80vh;
  overflow: auto;
  min-height: 420px;
  background: #fff;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modalLeft::--webkit-scrollbar {
  display: none;
}

.modalRight {
  display: none;
}

.modalRight > span {
  min-height: 100%;
}

.container > form {
  width: -webkit-fill-available;
}

.modalTitle {
  font-size: var(--fontSizeXL);
}
.description {
  /* display: flex; */
  flex-direction: column;
  gap: var(--spacing48);
  justify-content: center;
  align-items: center;
}

.modalBody {
  font-size: var(--fontSizeXS);
  text-align: left;
}

.button,
.buttonDeclined {
  display: grid;
  width: 100%;
  margin: var(--spacing40) 0px;
}

.buttonDeclined {
  margin-top: 0px;
}

.newsletterForm {
  margin-top: var(--spacing40);
  margin-bottom: var(--spacing40);
  width: 100%;
}

.forgotPassword,
.createAccount {
  font-size: var(--fontSizeXXS);
  color: var(--textGrey);
  text-decoration: underline;
  cursor: pointer;
}

.forgotPassword {
  text-align: right;
  margin-top: -8px;
}

.signUpInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  color: var(--textGrey);
  font-size: var(--fontSizeXXS);
}

.signUpText {
  color: var(--textBlack);
}

.error {
  font-size: var(--fontSizeXXS);
  color: var(--textRed);
  margin-top: -20px;
}

@media (--mobile) {
}

@media (--desktop) {
  .container {
    flex-direction: row;
  }
}

@media (min-width: 920px) {
  .modalRight {
    display: block;
    width: 50%;
    min-width: 395px;
    flex: 1;
    align-self: stretch;
    min-height: 395px;
    background-size: cover;
    background-position: center;
  }
}

@media (--desktopXL) {
}

@media (--desktop) and (min-height: 800px) {
  .modalLeft {
    overflow: hidden;
  }
}
