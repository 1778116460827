:root {
  /* ------  Fonts  ------ */
  --euclidCircularB: "Euclid Circular B";

  /* ------  Colors ----- */
  --colorWhite: #ffffff;
  --colorWestar: #edeae7;
  --colorQuicksand: #cb8e16;
  --colorRajah: #d5d717;
  --colorBlack: #000000;
  --colorGrey: #a5a5a5;
  --colorGreyLight: #707070;
  --colorOrange: #ff7913;
  --colorLightGrey: #f4f2f0;
  --colorGreySand: #d5ccba;

  /* ----- Text Colors ----- */
  --textWhite: #ffffff;
  --textBlack: #000000;
  --textGrey: #777777;
  --textRed: red;

  /* ------ Font Sizes ----- */
  --fontSizeXXS: 13px;
  --fontSizeXS: 14px;
  --fontSizeXSStatic: 14px;
  --fontSizeS: 16px;
  --fontSizeM: 19px;
  --fontSizeL: 24px;
  --fontSizeXL: 28px;

  --fontSizeOverlayS: 60px;
  --fontSizeOverlayM: 120px;
  --fontSizeOverlayL: 160px;

  /* ----- Font Weights ----- */
  --fontWeightStandard: 400;
  --fontWeightThick: 600;
  --fontWeightBold: 800;

  /* ------ Spacing ------ */
  --sectionSpacingTopDesktop: 35px;
  --sectionSpacingTopDesktopXL: 60px;

  --spacing4: 4px;
  --spacing8: 8px;
  --spacing16: 16px;
  --spacing20: 20px;
  --spacing24: 24px;
  --spacing32: 32px;
  --spacing40: 40px;
  --spacing48: 48px;
  --spacing56: 56px;

  --spacing72: 72px;
  --spacing80: 80px;
  --spacing88: 88px;

  --spacing120: 120px;
  --spacing160: 160px;
  --borderRadius: 8px;

  /* Date picker specific */
  --rdp-cell-size: 50px !important;
}

@media (--desktopXXL) {
  :root {
    --fontSizeXS: 17px;
  }
}
