.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  /* position: relative; */
}

.modal,
.modalWide,
.modalBasket,
.fullScreen {
  z-index: 100;
  background: white;
  margin: 1.75rem auto;
  max-width: 500px;
  width: 100%;
  padding: 2rem;
  left: 0;
  top: 10%;
  max-height: 90vh;
  /* overflow: auto; */
}

.modalBasket {
  min-width: 100vw;
  position: absolute;
  top: calc(-50vh - 28px);
  left: -50vw;
  padding: var(--spacing32);
  max-height: 100vh;
  min-height: 100vh;
  overflow: auto;
}

:global(.ios-device) .modalBasket {
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

.modalWide {
  max-width: 950px;
  padding: 0px;
  overflow: auto;
}

.fullScreen {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalWrapper {
  position: relative;
  max-width: 85%;
}

.fullScreenWrapper {
  max-width: 100vw;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalContent {
  flex: 1;
}

.modalHeaderWithImage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalCloseButton {
  position: absolute;
  /* font-size: 1.4rem; */
  font-weight: 700;
  /* line-height: 1.5; */

  background-color: var(--colorBlack);
  opacity: 1;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  transform: translate(-10px, 39px);
  right: 0px;
  top: 0px;
  height: 29px;
  width: 29px;
  padding: 0px;
  z-index: 10;
}

.modalCloseButton > svg {
  fill: var(--colorWhite);
  stroke: var(--colorWhite);
  transform: translate(0px, 1px);
}

.modalCloseButton:hover {
  background-color: var(--colorGrey);
}

.modalCloseButtonBasket {
  transform: translate(-31px, 25px);
  right: 0px;
  top: 0px;
  background-color: var(--colorWhite);
}
.modalCloseButtonBasket > svg {
  fill: var(--colorBlack);
  stroke: var(--colorBlack);
  transform: translate(0px, 1px);
}

.modalCloseButtonBasket:hover {
  background-color: transparent;
}
.closeMessage {
  position: absolute;
  color: var(--colorBlack);
  font-size: var(--fontSizeXS);
  transform: translate(-45px, 12px);
}

/* button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
} */

.buttonDefault {
  background: #247ba0;
  color: #fff;
}

.error {
  margin-top: 0;
  display: inline-block;
}

.shareIcons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.shareIcons > * {
  margin: 20px 20px;
}

@media (--desktop) {
  .modal {
    padding: 5rem;
  }

  .modalBasket {
    min-width: 50vw;
    position: absolute;
    top: calc(-50vh - 28px);
    left: 0;
    min-height: 100vh;
  }
}
