.container {
  gap: var(--spacing40);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container > form {
  width: -webkit-fill-available;
}

.modalTitle {
  font-size: var(--fontSizeXL);
}

.modalBody {
  font-size: var(--fontSizeXS);
  text-align: center;
}

.button {
  display: grid;
  margin-top: var(--spacing24);
}

.forgotPassword,
.createAccount {
  font-size: var(--fontSizeXXS);
  color: var(--textGrey);
  text-decoration: underline;
  cursor: pointer;
}

.forgotPassword {
  text-align: right;
  margin-top: -8px;
}

.signUpInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  color: var(--textGrey);
  font-size: var(--fontSizeXXS);
}

.signUpText {
  color: var(--textBlack);
}

.error {
  font-size: var(--fontSizeXXS);
  color: var(--textRed);
  margin-top: -20px;
}

.buttonContainer {
  width: 100%;
}

@media (--mobile) {
}

@media (--desktop) {
}

@media (--desktopXL) {
}
