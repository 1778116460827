.container {
  height: 100%;
  padding-top: var(--spacing48);
}

.titleSection {
  padding-bottom: var(--spacing40);
  margin-bottom: var(--spacing40);
  border-bottom: 1px solid var(--colorBlack);
}

.titleTop {
  display: flex;
  flex-direction: column;
  font-weight: var(--fontWeightThick);
}

.titleSection > div {
  padding-bottom: var(--spacing8);
}

.red {
  color: red;
}

.green {
  color: green;
}

.productSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing16);
}

.productItem {
  flex: 1;
  /* min-width: 80vw; */
}

.buttonSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: var(--spacing48);
  gap: var(--spacing32);
}

.buttonSection > button {
  width: 100%;
}

.checkoutMessage {
  text-align: center;
  margin-top: var(--spacing40);
  position: sticky;
  bottom: -32px;
  padding: var(--spacing24) 0px;
  background-color: var(--colorWhite);
  z-index: 10000;
}

.upgradeButton {
  padding-top: var(--spacing24);
}

@media (--desktop) {
  .container {
    padding-top: var(--spacing80);
  }

  .buttonSection {
    flex-direction: row;
    gap: var(--spacing56);
  }
  .titleTop {
    flex-direction: row;
    justify-content: space-between;
  }
  .titleSection > div {
    padding-bottom: 0px;
  }
  .productItem {
    flex: 1;
    min-width: 130px;
  }
}

@media (min-width: 945px) {
  .productSection {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (--desktopXL) {
  .productSection {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
