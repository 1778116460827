.bgContainer {
  display: flex;
  justify-content: center;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 15px 50px 15px;
  /* max-width: 1440px; */
  width: 100%;
}

.settingsSection {
  width: 100%;
  max-width: 860px;
  margin-top: 35px;
  margin-bottom: var(--spacing72);
  padding-left:var(--spacing16);
  padding-right:var(--spacing16);
}

.pageSection {
  width: 100%;
  max-width: 1320px;
  padding: 0px 15px;
  margin-bottom: var(--spacing56);
}

.columnReverse {
  flex-direction: column-reverse !important;
}

.tabletPadding {
  padding: 35px 10% 70px 10%;
}

.disableBottomPadding {
  padding-bottom: 0px !important;
}

.disableTabletPadding {
  padding: 0px;
}

@media (--desktop) {
  .section {
    padding: 35px 40px 70px 40px;
    flex-direction: row;
    /* min-height: 500px !important; */
  }

  .settingsSection {
    margin: 35px 40px 70px 40px;
  }

  .pageSection {
    margin-bottom: 180px;
    padding: 0px var(--spacing40);
  }

  .columnReverse {
    flex-direction: row !important;
  }
}

@media (--desktopXL) {
  .section {
    padding: 60px 60px 90px 60px;
  }
  .settingsSection {
    margin: 60px 60px 90px 60px;
  }
}


@media (min-width: 1700px) {
  .section {
    /* padding-top: 80px; */
    /* padding-bottom: 80px;  */
  }

  /* .bgContainer {
        min-height: 700px !important;
  } */
}