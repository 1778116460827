.logoContainer {
  display: flex;
  justify-content: center;
}

.logo {
  align-self: center;
}

.logo > svg {
  height: 260px !important;
  width: 260px !important;
}

@media (--desktop) {
  .logo > svg {
    height: 470px !important;
    width: 470px !important;
  }
}

@media (--desktopXL) {
  .logo > svg {
    height: 540px !important;
    width: 540px !important;
  }
}
