.content {
  /* width: ; */
  width: 100%;
  /* max-width: var(--contentMaxWidthPx); */
  /* padding: 0 var(--spacingSideMobile); */
  margin: 0 auto;
  /* margin-top: 75px; */
}

.layout {
  min-height: calc(100vh - 120px);
}


.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  z-index: 99999;
  gap: 32px;
}

.cover svg {
  max-width: 300px;
  max-height: 150px;
}

.cover p {
  max-width: 600px;
}

.cover a {
  text-decoration: underline;;
}