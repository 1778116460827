.footerContainer {
  padding: 0px 20px 0px 20px;
}

.linkTitle {
  text-decoration: none;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--fontSizeXXS);
  color: var(--textGrey);
  border-top: 1px solid var(--textGrey);
  padding: 5px 0px 5px 0px;
  padding-top: 10px;
  padding-bottom: 16px;
}

.footerLeft {
  display: flex;
  flex-direction: column;
}

.footerRight {
  display: flex;
  flex-direction: row;
}

.footerLeftLink {
  display: grid;
  gap: 3px;
  /* grid-template-columns: 1fr 1fr; */
}

.socialIcon {
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;
  background-color: #777777;
}

.socialIcon svg {
  display: block;
  width: 14px;
  height: 14px;
}


@media (--mobile) {

  .footerLeftLink {
    margin-top: 6px;
  }
}

@media (--desktop) {

  .socialIcon {
    margin-left: var(--spacing16);
    width: 40px;
    height: 40px;
  }

  .socialIcon svg {
      width: 17px;
        height: 17px;
  }
  .footer {
    width: 100%;
    max-height: initial;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 0px;
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .linkItem {
    padding: 0 40px 4px 0px;
  }

  .footerLeft {
    display: flex;
    flex-direction: row;
  }

  .footerRight {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
  }
}

@media (--desktopXL) {
}
