.container {
  /* max-width: 170px; */
  /* margin: 15px; */
  width: 100%;
}

.basketContainer,
.basketContainerSelected {
  position: relative;
  cursor: pointer;
}

.basketContainerSelected {
  border: 2px solid var(--colorOrange);
}

.imageContainer,
.basketImageContainer {
  position: relative;
  overflow: hidden;
}

.basketImageContainerUnavilable img {
  opacity: .3;
    filter: blur(1px);
}

.basketImageUnavailable {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.imageContainer * img,
.basketImageContainer * img {
  object-fit: contain;
}

.productImage {
  aspect-ratio: 1 / 1;
  width: 100%;
}

.image1 {
  background-color: var(--colorWestar);
}

.infoContainer a {
  display: block;
  width: 100%;
}

.image2 {
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
}
.image2 * img {
  object-fit: cover;
}

.image2:hover {
  opacity: 1;
  background-color: var(--colorWestar);
  transition: 200ms;
}

.favorite {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
}

.heartIcon button {
  padding: 0;
}

.favorite:hover svg path,
.favoriteActive svg path {
  fill: #000;
}

.plusIcon {
  background-color: var(--colorBlack);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.plusIcon:hover {
  background-color: var(--colorOrange);
}

.plusIcon > svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.favorite,
.plusIcon {
  z-index: 501;
}

.brand {
  margin: var(--spacing8) 0px;
}

.brand,
.basketName {
  font-weight: var(--fontWeightThick);
}
.price {
  color: var(--colorGrey);
  margin-top: 3px;
}

.productImage {
  /* position: relative; */
}

.productTag {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: var(--fontSizeXXS);
  padding: 4px 7px;
  background: var(--colorWhite);
  z-index: 10;
}

.name,
.price,
.basketName,
.basketSize {
  font-size: var(--fontSizeXS);
}

.closeButton {
  position: absolute;
  /* font-size: 1.4rem; */
  font-weight: 700;
  /* line-height: 1.5; */

  background-color: var(--colorBlack);
  opacity: 1;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  /* transform: translate(-10px, 39px); */
  top: -12px;
  right: -12px;
  height: 22px;
  width: 22px;
  padding: 0px;
  z-index: 10;
}

.closeButton > svg {
  fill: var(--colorWhite);
  stroke: var(--colorWhite);
  transform: translate(2.75px, 2.5px);
}

.closeButton:hover {
  background-color: var(--colorGrey);
}

@media (max-width: 370px) {
  .container {
    /* max-width: calc(100vw - 30px); */
    /* margin: 15px; */
  }
}

@media (min-width: 370px) {
  .container {
    /* max-width: calc(100vw / 2 - 30px); */
    /* margin: 15px; */
  }

  .imageContainer {
    max-height: calc(100vw / 2 - 30px);
  }
}

@media (min-width: 600px) {
  .imageContainer {
    max-height: calc(100 / 3 - 30px);
  }
}

@media (--desktop) {
  .container {
    /* max-width: 306px; */
    /* min-width: 180px; */
    margin: 0px;
  }
  .heartIcon {
    position: absolute;
    /* top: 20px; */
    /* left: 20px; */
  }
  .imageContainer {
    position: relative;
    /* max-height: calc(100 / 4 - 30px); */
  }

  .plusText {
    font-size: var(--fontSizeXXS);
  }

  .infoContainer {
    position: relative;
    display: flex;
  }

  .brand,
  .name {
    max-width: calc(100% - var(--spacing48));
  }

  .desktopPlusIconContainer {
    /* bottom: 0; */
    right: 0;
    top: 53px;
    position: relative;
    position: absolute;
  }
  .plusIcon {
    background-color: black;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    position: relative;
    transform: translate(9px, -22px);
  }

  .plusIcon > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
  }

  .plusText {
    transform: translateX(-1px) translateY(-4px);
    font-size: 12px;
    font-weight: var(--fontWeightThick);
  }
}

/* Basket Info */

.basketInfoContainer {
  padding-top: var(--spacing8);
}

.basketSize {
  padding-top: var(--spacing4);
}
