.header {
  width: 100%;
  /* height: 60px; */
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
  font-size: var(--fontSizeXXS);
  align-items: center;
  position: sticky;
  padding: 0 12px;
  top: -65px;
  background-color: var(--colorWhite);
  z-index: 1000;
  transition: all 0.5s;
}

.headerContent {
  padding: 12px 0;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.headerVisible {
  top: 0;
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.authHeaderleft {
  display: none;
}

.headerMiddle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownLinks {
  display: none;
  position: absolute;
  background-color: var(--colorWhite);
  min-width: 100px;
  padding-top: 10px;
  z-index: 1;
  transform: translateX(-8px);
}

.dropdownLinks a {
  color: black;
  padding: var(--spacing8) 0px var(--spacing8) var(--spacing8);
  text-decoration: none;
  display: block;
}

.dropdownLinks a:hover {
  color: var(--colorOrange);
  cursor: pointer;
}

.dropdown:hover .dropdownLinks {
  display: block;
}

.shopNow,
.burgerIcon,
.cartIcon {
  font-size: var(--fontSizeXXS);
  height: 30px;
  border-radius: 50px;
  background-color: var(--colorBlack);
  color: var(--textWhite);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.burgerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 8px; */
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.burgerIconLine {
  width: 14px;
  height: 1px;
  background: var(--colorWhite);
  transform-origin: center;
}

.burgerIconOpen .burgerIconLine:nth-child(2) {
  display: none;
}
.burgerIconOpen .burgerIconLine:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  top: 50%;
  left: 50%;
}
.burgerIconOpen .burgerIconLine:nth-child(3) {
  transform: translate(-50%, -50%) rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 50%;
}

.shopNow:hover,
.burgerIcon:hover,
.cartIcon:hover {
  background-color: var(--colorOrange);
}

.burgerIcon,
.cartIcon {
  height: 28px;
  width: 28px;
}

.heartIcon {
  z-index: 2;
  margin-right: 7px; /* 13+7 = 20px spacing */
}

.heartIcon svg {
  display: block;
}

.shopNow {
  max-height: 29px;
  padding: 12px;
  display: none;
}

.burgerIcon > svg {
  fill: var(--colorWhite);
  stroke: var(--colorWhite);
}

.closeIcon > svg {
  transform: translate(-0.5px, 1px);
}

.burgerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(var(--app-height) + 65px);
  width: 100%;
  background: var(--colorBlack);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.burgerOverlayActive {
  opacity: 0.4;
  pointer-events: initial;
}

.burger {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: var(--spacing24);
  position: absolute;
  padding-left: 104px;
  padding-top: 126px;
  top: 0;
  left: 0;
  width: 100%;
  /* min-height: 100vh; */
  height: 100vh;
  height: var(--app-height);
  /* min-height: -webkit-fill-available; */
  background: var(--colorWhite);
  margin-left: 0;
  transform: translateX(-100%);
  transition: transform 0.4s;
  z-index: 10;
  background-color: var(--colorWestar);
  text-align: left;
}

.burgerIconBurger {
  position: absolute;
  top: 14px;
  left: 15px;
}

.burgerItemSmall {
  position: absolute;
  left: 27px;
  bottom: 24px;
  font-weight: var(--fontWeightStandard);
}

.burgerOpen {
  transform: translateX(0);
}

.burgerOpenHeaderHidden {
  transform: translateY(65px);
}

.burgerItem {
  font-size: var(--fontSizeXL);
  cursor: pointer;
  font-weight: var(--fontWeightThick);
}

.burgerItem:hover {
  color: var(--colorOrange);
}

.hidden {
  display: none;
}

.ORLogo {
  cursor: pointer;
}

.ORLogo svg {
  display: block;
  width: auto;
  height: 25px;
}

.loginText,
.cartText {
  text-decoration: none;
  font-weight: var(--fontWeightThick);
  color: black;
  display: none;
  cursor: pointer;
}

.cartContainer {
  display: flex;
  align-items: center;
  gap: 13px;
}

.bm-burger-button {
  background-color: var(--colorBlack);
}

@media (--desktop) {
  .burgerIcon {
    gap: 5px;
  }

  .ORLogo svg {
    height: 34px;
  }

  .burgerIcon,
  .cartIcon {
    height: 30px;
    width: 30px;
  }

  .header {
    padding: 0 30px;
  }
  .loginText,
  .cartText {
    display: inline;
  }

  .shopNow {
    display: flex;
  }
  .authHeaderleft {
    display: flex;
    gap: var(--spacing16);
  }

  .burger {
    width: 460px;
    justify-content: initial;
    align-items: initial;
    padding-left: 60px;
    padding-top: 144px;
  }

  .burgerItemSmall {
    bottom: 35px;
    left: 60px;
  }
}

@media (--desktopXL) {
}

/* Subscription banner */

.subscriptionBanner {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing16) 0;
  background: #fff;
  border-top: 1px solid var(--colorGreyLight);
  margin-top: -1px;
  cursor: pointer;
}

.subscriptionBanner p {
  margin: 0;
  font-weight: var(--fontWeightBold);
}

.limitError {
  color: var(--textRed);
}

.subscriptionBannerLeft {
  display: flex;
  align-items: flex-start;
}

.subscriptionBannerRight {
  text-align: right;

  /* font-size: var(--fontSizeXS); */
}

@media (--desktop) {
  .subscriptionBannerLeft {
    align-items: center;
  }

  .subscriptionBannerRight {
    display: flex;
    gap: 18px;
    align-items: center;
    text-align: initial;
  }

  .subscriptionBannerDivider {
    height: 23px;
    width: 1px;
    background-color: var(--colorGreyLight);
  }
}

.backBtn {
  margin-right: var(--spacing16);
  display: flex;
  align-items: center;
  font-size: var(--fontSizeXS);
  font-weight: var(--fontWeightThick);
}

.backBtn svg {
  margin-right: 11px;
  /* width: 18px; */
  /* height: 13px; */
}
