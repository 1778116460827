.inputContainer {
  width: inherit;
  font-size: var(--fontSizeXS);
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing8);
}

.formContainer {
  width: inherit;
}

.inputContainer input,
.inputContainer select,
.inputContainer textarea {
  font-size: var(--fontSizeXS);
  border: none;
  min-height: 36px;
  width: 100%;
  background-color: var(--colorWestar);
  margin-bottom: var(--spacing8);
  font-family: var(--euclidCircularB);
}

.inputContainer textarea {
  padding-top: 6px;
}

.inputContainer label {
  display: block;
  margin-top: 4px;
}

.fieldInput {
  margin-bottom: var(--spacing20);
}

.checkbox {
  display: flex;
  flex-direction: row-reverse;
}

.checkbox input {
  margin-right: var(--spacing16);
  margin-left: 0px;
  width: 40px;
}

.singleField {
  width: 100%;
  position: relative;
}

.singleFieldHalfWidth {
  width: calc(50% - 4px) !important;
  display: inline-block;
  position: relative;
}

.error {
  position: absolute;
  right: 0;
  transform: translateY(-34px);
  margin-right: 5px;
  margin-bottom: 4px;
  color: var(--textRed);
}

.checkboxError {
  transform: translateY(85px);
}

.requiredLabel {
  color: var(--textGrey);
  font-size: 10px;
}

.resetFieldButton {
  z-index: 1;
  position: absolute;
  right: 25px;
  transform: translateY(50%);
  cursor: pointer;
}

@media (--desktop) {
}

@media (--desktopXL) {
  /* .inputContainer input {
    max-width: 223px;
  } */
}
