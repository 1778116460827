.inputContainer input {
  font-size: var(--fontSizeXS);
  border-radius: 50px;
  padding: 12px 24px 12px 24px;
  width: 100%;
  border: 1px solid var(--colorGrey);
  min-height: 36px;
}

.fieldInput {
  margin-bottom: var(--spacing20);
}

.checkbox {
  display: flex;
}

.error {
  font-size: var(--fontSizeXXS);
  text-align: right;
  margin-top: -20px;
  margin-bottom: 4px;
  color: var(--textRed);
}

.resetFieldButton {
  z-index: 1;
  position: absolute;
  right: 25px;
  transform: translateY(50%);
  cursor: pointer;
}

@media (--desktop) {
  /* .inputContainer input {
    max-width: 297px;
  } */
}

@media (--desktopXL) {
  /* .inputContainer input {
    max-width: 223px;
  } */
}
